
import StyledHeader from "../../components/StyledHeader";
import {Button, Divider, Typography} from "antd";
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import {getAnswerByQuestionsId} from "../../services/api/SnsAPI";
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from "moment";

const { Title, Paragraph } = Typography;
interface QuestionAnswerInfo {
    id: number;
    answeredBy: {
        id: string;
        name: string;
        email: string;
        role: string;
        password: string;
    };
    question: {
        id: number;
        tags: {
            id: number;
            name: string;
        }[];
        category: {
            id: number;
            name: string;
        };
        userId: {
            id: string;
            name: string;
            email: string;
            role: string;
            password: string;
        };
        title: string;
        content: string;
        createdAt: string;
        status: string;
        source: string;
    };
    content: string;
    answeredAt: string;
}



const ViewQuestion = () => {
    const { questionId } = useParams();
    const [questionAns, setQuestionAns] = useState<QuestionAnswerInfo>();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    useEffect(() => {
        console.log("Question log")
        console.log(questionId)
        if (questionId) {
            getAnswerByQuestionsId(questionId)
                .then(data => {
                    console.log("Data log")
                    console.log(data)
                    setQuestionAns(data)
                    setIsLoading(false); // set isLoading to false after the API call is completed
                })
                .catch(error => {
                    console.log(error);
                    navigate("/category")
                    setIsLoading(false); // set isLoading to false in case of error
            });
        }
        else{
            navigate("/category")
        }
    }, []);

    return (
        <>
            <StyledHeader />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <br />
                <Button
                    onClick={() => goBack()}
                    type="primary"
                    className="float-left bg-secondary"
                    icon={<span className="mb-1 mr-1"><ArrowLeftOutlined /></span>}
                >
                    Back
                </Button>
                {questionAns ? (
                    <div className="mt-6">
                        <Typography.Title className="text-primary text-center">
                            {questionAns.question.title}
                        </Typography.Title>
                        <div className="border-2 rounded-lg border-primary p-3 pb-0 mb-10 flex">
                            <Typography.Paragraph className="text-base">
                                Answered By: {questionAns.answeredBy.name}
                            </Typography.Paragraph>
                            <Typography.Paragraph className="text-base ml-auto">
                                Answered on:
                                {questionAns.answeredAt
                                    ? moment(questionAns.answeredAt).format('DD MMM YYYY, hh:mm A')
                                    : "date unknown"}
                            </Typography.Paragraph>
                        </div>

                        <div className="border-2 rounded-lg border-primary p-3 mb-5 bg-grey-background">
                            <Typography.Title level={3} className="text-primary">
                                Question
                            </Typography.Title>
                            <Typography.Paragraph className="text-base">
                                {questionAns.question.content}
                            </Typography.Paragraph>
                        </div>
                        <Typography.Title level={2} className="text-primary mb-0">
                            Answer
                        </Typography.Title>
                        <Divider className="bg-secondary mt-0"></Divider>
                        <div dangerouslySetInnerHTML={{ __html: questionAns.content }}></div>
                        {/*<Typography.Paragraph className="text-base" >*/}
                        {/*    {questionAns.content}*/}
                        {/*</Typography.Paragraph>*/}
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </>
    );
};



export default ViewQuestion