import React from "react";
import {Link} from "react-router-dom";
import AskatizahLogo from "../../assets/askatizah_logo.png";
import {Button, Typography} from "antd";
import QuestionReview from "../../pages/admin/QuestionReview";
import { LogoutOutlined } from '@ant-design/icons';

interface AdminHeaderProps {
    userName?: string;
}
const AdminHeader: React.FC<AdminHeaderProps> = ({userName}) =>  {

    return (
        <>
            {/*Specify color */}
            <div className="w-full">
                <nav className="bg-white py-2.5 rounded">
                    <div className="p-4 flex justify-end mr-2">
                        <Typography.Title level={3} className="mr-8"> {userName} </Typography.Title>


                            <Link to={"/adminlogin"}>
                                <Button type={"primary"} className="bg-secondary " size={"large"} icon={<span className="mb-1 mr-1.5"><LogoutOutlined/></span> }>
                                    Logout
                                </Button>
                            </Link>

                        {/*<div className="hidden w-full md:block md:w-auto justify-items-end">*/}

                        {/*    <ul className="flex flex-row-reverse p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">*/}
                        {/*        <li>*/}
                        {/*            <Link to={"/"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>*/}
                        {/*                Home*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to={"/category"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>*/}
                        {/*                Questions Bank*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to={"/submitQuestion"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>*/}
                        {/*                Submit a Question*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*        <li className="-order-1">*/}
                        {/*            <Link to={"/adminlogin"}>*/}
                        {/*                <Button type={"primary"} className="bg-secondary " size={"large"} >*/}
                        {/*                    Logout*/}
                        {/*                </Button>*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </nav>
            </div>
        </>
    )
}

export default AdminHeader