import React, {useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "../../components/TextEditor";
import {Button, Form, Input, Typography, Result, Select, Tag, message} from 'antd';
import StyledHeader from "../../components/StyledHeader";
import {Link} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {
    getAllCategory,
    getAllCategoryWithoutNotSure,
    getAllTags,
    QuestionUserFormDetails, submitUserWebsiteQuestionData
} from "../../services/api/SnsAPI";
import {CloseOutlined} from "@ant-design/icons";

const { Item } = Form;
const { Title, Text } = Typography;

// interface QuestionUserFormDetails {
//     email: string;
//     title: string;
//     body: string;
// }

// interface QuestionUserFormRequest {
//     user_id
// }


const QuestionForm = (props:any) => {
    const onSubmit = (formDetails: QuestionUserFormDetails ) => {
        console.log("Form Details: ")
        console.log(formDetails);
        setLoadings(true)

        // // Just for testing
        // setTimeout(() => {
        //     // Change the upload status here for testing also
        //     // props.doneUpload(true)
        //     // props.uploadStatus(true)
        //     setLoadings(false);
        // }, 3000);



        submitUserWebsiteQuestionData(formDetails)
            .then(r => {
                message.success("Question Submitted Successfully")
                props.doneUpload(true)
                props.uploadStatus(true)
                setLoadings(false);

                console.log(r)
            })
            .catch(e => {
                message.error("Question Submission Failed")
                console.log(e)
                setLoadings(false);

            })



    };

    interface Category {
        name:string;
        id:number;
    }

    const [loadings, setLoadings] = useState<boolean>(false);

    const removeIcon = () => <CloseOutlined style={{ color: "black" }} />;
    const [categoryOptions, setCategoryOptions] = useState<Category[] | void>([{name:"something", id:1}]);



    useEffect(() => {
        //      Call Category and tags for all.
        getAllCategory()
            .then((res) => {
                setCategoryOptions(res)
            })
            .catch((e) => {
                message.error("Failed to get categories")
                console.log(e)
            })

    },[])

    return (
        <div className="container mx-auto px-4">
            <div className="mt-5 mb-10">
                {/* <Title className="p-0 underline underline-offset-8 decoration-secondary">Ask an Asatizah!</Title> */}
                <p className="text-4xl font-bold mb-5 underline underline-offset-8 decoration-secondary"> Ask an Asatizah! </p>
                <Text className="text-base">Ask a question to our Azatizahs and we will notify you via email when it has been answered!</Text>
            </div>
            <Form layout="vertical" onFinish={onSubmit}>
                <div className="flex flex-col md:flex-row mb-10">
                    <div className="md:flex-1 md:mr-2 mb-4 md:mb-0">
                        <Title level={3}>Email Address</Title>
                        <Form.Item name="email" rules={[{ required: true }]}>
                            <Input className="drop-shadow-md" size="large" placeholder="eg... Nullifiers of Wudhu/Ablution" />
                        </Form.Item>
                    </div>

                    <div className="md:flex-1 md:ml-2 mb-4 md:mb-0">
                        <Title level={3}>Question Title</Title>
                        <Form.Item name="title" rules={[{ required: true }]}>
                            <Input className="drop-shadow-md" size="large" placeholder="eg... Nullifiers of Wudhu/Ablution" />
                        </Form.Item>
                    </div>

                    <div className="md:flex-1 md:ml-2">
                        <Title level={3}>Category</Title>
                        <Form.Item name="categoryId" rules={[{ required: true }]}>
                            <Select size="large" className="drop-shadow-md w-full" placeholder="Fiqh">
                                {categoryOptions?.map((category) => {
                                    return (
                                        <Select.Option key={category.id} value={category.id}>
                                            {category.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            <div className="">
                <Title level={3}>Question</Title>
                    <Item
                        name="questionContent"
                        rules={[
                            {
                                required: true,
                                message: 'Dont forget to type your question'
                            },
                        ]}
                    >
                        <TextArea
                            size="large"
                            className="drop-shadow-md"
                            rows={4}
                            placeholder="eg... How do I take wudhu and what are the nullfiers of wudhu"
                        />
                        {/* @ts-ignore */}
                        {/*<TextEditor />*/}
                    </Item>

                    <Item>
                        <div className="float-right mt-2">
                            <Button type="primary" className="bg-secondary" size={"large"} loading={loadings} htmlType="submit">
                                Submit Question
                            </Button>
                        </div>
                    </Item>
            </div>
            </Form>
        </div>
    )
}

const ResultMessage = (props: any) => {
    const SucessMessage = () => {
        return (
            <Result
                status="success"
                title="Successfully Sent!"
                subTitle="Alhamdullilah! We've received your question. Do give us some time for it to be answered. We will notify you when it is ready."
                extra={[
                    <Link to="/">
                        <Button type="primary" className="bg-secondary hover:bg-primary" key="console">
                            Return to home
                        </Button>
                    </Link>

                ]}
            />
        )
    }
    const ErrorMessage = () => {
        return (
            <Result
                status="error"
                title="Submission Failed"
                subTitle="Something went wrong, try again"
                extra={[
                    <Link to="/">
                        <Button type="primary" className="bg-secondary" ghost>
                            Back to Home
                        </Button>
                    </Link>

                ]}
            />
        )
    }
    console.log(props.uploadStatus)
    if(props.uploadStatus) {
        return <SucessMessage/>
    }
    return <ErrorMessage/>


}


const SubmitQuestion = () => {
    const [showResultsMessage, setShowResultsMessage] = useState(false)
    const onShowMessageChange = (stateChange: boolean) => {
        setShowResultsMessage(stateChange)
    }

    const [uploadResults, setUploadResults] = useState(false)
    const onChangeUploadResult = (stateChange: boolean) => {
        setUploadResults(stateChange)
    }

    return (
        <>
            <StyledHeader/>
            {showResultsMessage ?  <ResultMessage uploadStatus={uploadResults}/> : <QuestionForm doneUpload={onShowMessageChange} uploadStatus={onChangeUploadResult}/>
            }
        </>
    )
}



export default SubmitQuestion