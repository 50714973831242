import axios from "axios";
import BasicTenentsIcon from "../../assets/CategoryIcons/basic-tenets.png";
import FiqhIcon from "../../assets/CategoryIcons/fiqh.png";
import HadithIcon from "../../assets/CategoryIcons/hadith.png";
import QuranIcon from "../../assets/CategoryIcons/quran.png";
import SeerahIcon from "../../assets/CategoryIcons/seerah.png";
import SocialIcon from "../../assets/CategoryIcons/social.png";
import PrayerIcon from "../../assets/CategoryIcons/prayer.png";

import {CategoryResponse, CategoryWithImage} from "../../../types/api/categoryTypes";

const BaseUrl = process.env.REACT_APP_SNS_API_URL

export interface CategoryType {
    id: number;
    name: string;
}

// TODO: Get All tags, then map all of them to the images present

export const getCategoriesApi = async () => {
    console.log(BaseUrl)
    const getIcon = (title: string) => {
        switch (title) {
            case "Basic":
                return BasicTenentsIcon;
            case "Fiqh":
                return FiqhIcon;
            case "Hadith":
                return HadithIcon;
            case "Quran":
                return QuranIcon;
            case "Seerah":
                return SeerahIcon;
            case "Social":
                return SocialIcon
            case "Prayer":
                return PrayerIcon;
            default:
                return null;
        }
    };

    /* ---- RESPONSE EXAMPLE ------
        {
            "id": 1,
            "name": "Quran"
        },
     */
    try {
        console.log("Base URL: " + BaseUrl)
        console.log("Full URL: " + BaseUrl + "/categories")
        const response = await axios.get(BaseUrl + "/categories", {withCredentials: true});
        const categories: CategoryResponse[] = response.data.filter((category: CategoryResponse) => category.id !== 1);
        console.log(BaseUrl)
        console.log(categories);
        const categoriesWithIcon: CategoryWithImage[] = categories.map((category: CategoryResponse) => {
            return { ...category, iconUrl: getIcon(category.name) };
        });
        return categoriesWithIcon;
    } catch (exception) {
        console.log(exception);
        return null;
        // TODO : Error handling
    }

}


export const postQuestions = async (questionForm: any) => {
    axios.post(BaseUrl + "/questions", questionForm)
            .then(response => {
                return response
            })
}
export interface QuestionCardInfo {
    id: number;
    tags: { id: number; name: string; color:string }[];
    category: { id: number; name: string };
    title: string;
    content: string;
}

//   Filter to only take like top 30 or smtg
export const getAllQuestions = async () => {
    return await axios.get(BaseUrl + "/questions")
        .then(response => {
            const data = response.data
            console.log(response)
            const filteredData : QuestionCardInfo[] = data.map((question: any) => ({
                id: question.id,
                tags: question.tags,
                category: question.category,
                title: question.title,
                content: question.content,
            }));
            console.log("This is filtered data")
            console.log(filteredData)
            return filteredData
        })
        .catch(exception => {
            console.log(exception)
            return null
        })
}


export const getQuestionsByCategoryId = async (id: number) => {
    return axios.get(BaseUrl + "/questions/category/" + id)
        .then(response => {
            const data = response.data;
            const dataArray = Array.isArray(data) ? data : [data];
            const filteredData: QuestionCardInfo[] = dataArray
                .filter((question: any) => question.status === "Published")
                .map((question: any) => ({
                    id: question.id,
                    tags: question.tags,
                    category: question.category,
                    title: question.title,
                    content: question.content,
                }));
            return filteredData;
        })
        .catch(error => {
            console.log(error);
        });
}
// interface AnswerInfo {
//     id :number
// }
// interface ViewQuestionInfo {
//     answer:
// }


// TODO: Retrieve Answer object from the /answer/{questionId} endpoint
export const getAnswerByQuestionsId = async (id: string) => {
    return axios.get(BaseUrl + "/answers/" + id)
        .then(response => {
            console.log("Id check")
            console.log(response.data[0])

            return response.data[0]
        })
        .catch(error => {
            console.log(error);
        });
}
export interface QuestionProcessingPublished {
    id: number;
    title: string;
    createdAt: Date;
    content: string;
    status: string;
    source: string;
    category: {
        id: number;
        name: string;
    };
    tags: {
        id: number;
        name: string;
        color:string;
    }[];
}

export interface QuestionProcessing {
    id: number;
    category: { id: number, name:string };
    title: string;
    createdAt: Date;
    content: string;
    status: string;
    source: string;
}

export const getQuestionByStatusProcessing = async () => {
    // TODO IN THE FUTURE WITH PROPER ENDPOINT. CURRENTLY WILL TAKE ALL QUESTIONS THEN FILTER, INEFFICIENT
    return await axios.get(BaseUrl + "/questions")
        .then(response => {
            const data = response.data
            console.log(data)
            const filteredData : QuestionProcessing[] = data
                .filter((question: any) => question.status === "Submitted")
                .map((question: any) => ({
                    id: question.id,
                    category: question.category,
                    title: question.title,
                    createdAt: question.createdAt,
                    content: question.content,
                    status: question.status,
                    source: question.source
                }));
            console.log(filteredData)
            return filteredData
        })
        .catch(error => {
            console.log(error);
            }
        )
}

export const getQuestionByStatusDuplicate = async () => {
    // TODO IN THE FUTURE WITH PROPER ENDPOINT. CURRENTLY WILL TAKE ALL QUESTIONS THEN FILTER, INEFFICIENT
    return axios.get(BaseUrl + "/questions")
        .then(response => {
            const data = response.data
            console.log(data)
            const filteredData : QuestionProcessingPublished[] = data
                .filter((question: any) => question.status === "Published")
                .map((question: any) => ({
                    id: question.id,
                    title: question.title,
                    createdAt: question.createdAt,
                    content: question.content,
                    status: question.status,
                    source: question.source,
                    category: question.category,
                    tags: question.tags
                }));
            console.log(filteredData)
            return filteredData
        })
        .catch(error => {
                console.log(error);
            }
        )
}


export const getQuestionsForAsatizahReview = async () => {
    // TODO IN THE FUTURE WITH PROPER ENDPOINT. CURRENTLY WILL TAKE ALL QUESTIONS THEN FILTER, INEFFICIENT
    return axios.get(BaseUrl + "/questions")
        .then(response => {
            const data = response.data
            console.log(data)
            const filteredData : QuestionProcessingPublished[] = data
                .filter((question: any) => question.status === "Processed")
                .map((question: any) => ({
                    id: question.id,
                    title: question.title,
                    createdAt: question.createdAt,
                    content: question.content,
                    status: question.status,
                    source: question.source,
                    category: question.category,
                    tags: question.tags
                }));
            console.log(filteredData)
            return filteredData
        })
        .catch(error => {
                console.log(error);
            }
        )
}


interface QuestionUpdate {
    id: number;
    tags?: { id: number }[];
    category?: { id: number };
    title: string;
    content: string;
    status: number;
}

export const updateQuestionDetailsForAdminProcessing = async (question: any) => {
    return await axios.put(BaseUrl + "/question", question)
        .then(response => {
            console.log("Update done")
            console.log(response)
            return response.data
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}

export const updateQuestionStatusRejected = async (question: QuestionUpdate) => {
    return await axios.put(BaseUrl + "/question/status/" + question.id, {"status": question.status})
        .then(response => {
            console.log("Update done")
            console.log(response)
            return response.data
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}
interface UserDetails {
    username: string;
    password: string;
}

export interface UserResponse {
    email: string;
    id: string;
    name:string;
    password:string;
    role:string;
}


export const AuthenticateLogin = async (userDetails: UserDetails) => {
    return axios.post(BaseUrl + "/login", userDetails)
        .then(response => {
            const allUserDetails : UserResponse= response.data
            console.log("Update done")
            console.log(response)
            return allUserDetails
        })
        .catch(error => {
            console.log(error);
        });

}

export interface AnswerRequest {
    answeredBy: {
        id: string | null;
    };
    content: string;
    status: number;
    question: {
        id: number | undefined;
    };
}

 // THIS ONE
export interface AnswerResponse {
    questionContent: string;
    createdAt: Date;
    answerContent: any;
    id: number;
    source: string;
    title: string;
    category: { id: number; name: string };
    status: string;
    tags: { id: number; name: string, color:string }[]
}



export const SubmitAnswerToDatabase = async (answerDetails:AnswerRequest ) => {
    return axios.post(BaseUrl + "/answer", answerDetails)
        .then(response => {
            return response
            /*const allUserDetails : UserResponse= response.data
            console.log("Update done")
            console.log(response)
            return allUserDetails*/
        })
        .catch(error => {
            console.log(error);
            throw new Error("Error in submitting answer");
        });
}

interface QuestionAnswerIntermediate {
    id: number;
    title: string;
    createdAt: Date;
    questionContent: string;
    answerContent: string;
    status: string;
    source: string;
    category: {
        id: number;
        name: string;
    };
    tags: {
        id: number;
        name: string;
        color:string
    }[];
}

export const getQuestionsForPublishedQuestions = async () => {
    // TODO IN THE FUTURE WITH PROPER ENDPOINT. CURRENTLY WILL TAKE ALL QUESTIONS THEN FILTER, INEFFICIENT
    console.log("In")
    return axios.get(BaseUrl + "/questions")
        .then(async response => {
            const data = response.data
            console.log(data)
            const filteredData: QuestionAnswerIntermediate[] = data
                .filter((question: any) => question.status === "Published")
                .map((question: any) => ({
                    id: question.id,
                    title: question.title,
                    createdAt: question.createdAt,
                    questionContent: question.content,
                    status: question.status,
                    source: question.source,
                    category: question.category,
                    tags: question.tags
                }));
            console.log("Filtered")
            console.log(filteredData)

            interface AnswerData {
                id: number;
                content: string;
                questionId: number;
                answeredBy: {
                    id: string;
                    name: string;
                    email: string;
                    role: string;
                    password: string;
                };
                question: {
                    id: number;
                    tags: {
                        id: number;
                        name: string;
                    }[];
                    category: {
                        id: number;
                        name: string;
                    };
                    userId: {
                        id: string;
                        name: string;
                        email: string;
                        role: string;
                        password: string;
                    };
                    title: string;
                    content: string;
                    createdAt: string;
                    status: string;
                    source: string;
                };
                answeredAt: string;
            }


            // Make another Axios call to retrieve answer content
            // const answerIds = filteredData.map((question) => question.id);

            const answersResponse = await axios.get(`${BaseUrl}/answers`)
                .then(response => {
                    const answersData = response.data;
                    console.log("Answer data")
                    console.log(answersData)
                    // const filteredData = answersData.filter( (data:any ) => data.question.status === "Published");
                    const publishedAnswerData = answersData.filter((answer: any) => answer.question.status === "Published");
                    console.log(publishedAnswerData)
                    console.log("Published questions")
                    const processedData = filteredData.map((question:any ) => ({
                        ...question,
                        answerContent: publishedAnswerData
                            .filter((answer: AnswerData) => (answer.question.id === question.id && answer.question.status === "Published"))
                            .map((answer: AnswerData) => answer.content)
                            .join(", "),
                    }));
                    console.log("Processed")
                    console.log(processedData)
                    return processedData;
                });
            return answersResponse;


            // const answersResponse = await axios.get(
            //     `${BaseUrl}/answer/${answerIds.join(",")}`
            // );
            // const answersData = answersResponse.data;
            //
            // // Combine question and answer data
            // const processedData = filteredData.map((question) => ({
            //     ...question,
            //     answerContent: answersData.find(
            //         (answer: AnswerData) => answer.id === question.id
            //     )?.content,
            // }));
            //
            // console.log(processedData);
            // return processedData;
        })
        .catch(error => {
            console.log(error);
        })
}

export const getAllCategory = async () => {
    return axios.get(BaseUrl + "/categories")
        .then(response => {
            const data: CategoryResponse[] = response.data
            console.log(data)

            return data;

        })
        .catch(error => {
                console.log(error);
            }
        )
}


export const getAllCategoryWithoutNotSure = async () => {
    // TODO IN THE FUTURE WITH PROPER ENDPOINT. CURRENTLY WILL TAKE ALL QUESTIONS THEN FILTER, INEFFICIENT
    return await axios.get(BaseUrl + "/categories")
        .then(response => {
            const data: CategoryResponse[] = response.data
            return data.filter(category => category.id !== 1);
        })
        .catch(error => {
                console.log(error);
            }
        )
}

interface TagResponse {
    id: number;
    name: string;
    color: string;
}
export const getAllTags = async () => {
    return axios.get(BaseUrl + "/tags")
        .then(response => {
            const data: TagResponse[] = response.data
            console.log(data)
            return data;
        })
        .catch(error => {
                console.log(error);
            }
        )
}
export interface QuestionUserFormDetails {
    email: string;
    title: string;
    categoryId: string;
    questionContent: string;
}

interface SubmitQuestionRequest {
    category: {
        id: number;
    };
    userId: {
        id: string;
        // name: string;
        // email:string;
        // role:number;
        // password:string;
    };
    title: string;
    content: string;
    source: number;
}


export const submitUserWebsiteQuestionData = async(formDetails : QuestionUserFormDetails) => {
    // try {
    // Send a POST request to the /user endpoint to check if the user exists
    console.log("Check starting")
    const userIdGenerate = "ws_" + formDetails.email;
    console.log(userIdGenerate)

    const questionRequestBody: SubmitQuestionRequest = {
        category: {
            id: parseInt(formDetails.categoryId),
        },
        userId: {
            id: userIdGenerate,
            // name: "Website User",
            // email: formDetails.email,
            // role: 2,
            // password: "-"
        },
        title: formDetails.title,
        content: formDetails.questionContent,
        source: 2
        }

    console.log(questionRequestBody)

    // Send a POST request to the /question endpoint to submit the question data
   return await axios.post(BaseUrl + "/question", questionRequestBody)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log(error)
            throw new Error("API Error in submitting question");
        });
}

export const getQuestionCountForStatusProcessAndStatusUnanswered = async () => {
    return await axios.get(BaseUrl + "/questions")
        .then(response => {
            const data = response.data
            console.log(response)
            const filteredData : QuestionCardInfo[] = data.map((question: any) => ({
                id: question.id,
                tags: question.tags,
                category: question.category,
                title: question.title,
                content: question.content,
            }));
            console.log("This is filtered data")
            console.log(filteredData)
            return filteredData
        })
        .catch(exception => {
            console.log(exception)
            return null
        })
}
export interface DuplicateQuestionRequest {
    userId:string | null;
    duplicateQuestionId?: number ;
    currentQuestionId?: number ;
}


export const duplicateQuestionEndpoint = async (requestData: DuplicateQuestionRequest) => {
    console.log(requestData)
    return await axios.post(BaseUrl + "/answer/duplicate", requestData)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log(error)
            throw new Error("API Error in submitting question");
        });
}

export interface SimpleAnswerData {
    id?: number;
    content:string;
}

export const getAnswerByQuestionId = async (questionId: number|undefined) => {
    return axios.get(BaseUrl + "/answers/" + questionId)
        .then( (res) => {
            console.log("Res")
            console.log(res)
            const answerData = res.data[0]
            console.log(answerData)
            const relevantAnswerDataOnly: SimpleAnswerData  = {
                id: answerData.id,
                content: answerData.content
            }
            console.log("relevantAnswerDataOnly")
            console.log(relevantAnswerDataOnly)
            return relevantAnswerDataOnly
        })
        .catch( () => {
            return null
        })
}


export interface EdittingFormData {
    // questionId: number | undefined,
    // answerId: number | undefined,
    title: string,
    category: number,
    tags: number[],
    questionContent:string,
    answerContent:string,
}

export interface EdittingRequest {
    questionId: number | undefined,
    answerId: number | undefined,
    title: string,
    categoryId:number,
    tags: {
        id: number;
        name: string;
        color: string;
    }[],
    questionContent:string,
    answerContent:string,
}

export const updateQuestionAnswerEditData = async (requestData: EdittingRequest) => {
    console.log("Request data")
    console.log(requestData)
    return await axios.put(BaseUrl + "/questionAnswerEdit", requestData)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log(error)
            throw new Error("API Error in submitting question");
        });
}






        // catch (error) {
        // console.log(error);
        // }






// .then((response) => {
//     console.log(response);
// })
