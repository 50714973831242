import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import SubmitQuestion from "./pages/SubmitQuestion";
import Category from "./pages/Category";
import ViewQuestion from "./pages/ViewQuestion";
import ErrorPage from "./pages/ErrorPage";
import QuestionReview from "./pages/admin/QuestionReview";
import QuestionBank from "./pages/admin/QuestionBank";
import PublishedQuestions from "./pages/admin/PublishedQuetions";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminLogin from "./pages/admin/AdminLogin";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home/>}   />
        <Route path="/submitQuestion" element={<SubmitQuestion/>}/>
        <Route path="/category" element={<Category/>}/>
        <Route path="/viewQuestion/:questionId" element={<ViewQuestion/>}/>
        <Route path="/adminlogin" element={<AdminLogin/>}/>
        <Route path="/adminDashboard" element={<AdminDashboard/>}/>
        <Route path="/questionReview" element={<QuestionReview/>}/>
        <Route path="/questionBank" element={<QuestionBank/>}/>
        <Route path="/publishedQuestions" element={<PublishedQuestions/>}/>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
  );
}

export default App;
