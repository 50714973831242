import SideMenu from "../../../components/AdminSideMenu";
import 'react-quill/dist/quill.snow.css';
import {Space, Table, Tag, Button, Form, Typography, message} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AdminLayout from "../../../Layout/AdminLayout";
import React, {useEffect, useState} from "react";
import {
    AnswerRequest,
    getQuestionByStatusProcessing,
    getQuestionsForAsatizahReview,
    QuestionProcessing, SubmitAnswerToDatabase
} from "../../../services/api/SnsAPI";
import moment from "moment/moment";
import TextArea from "antd/es/input/TextArea";
import TextEditor from "../../../components/TextEditor";
import OnChangeHandler from "../../../components/TextEditor";


interface AnswerFormData {
    answer: string;
    content : string;
    title: string;
}

const { Title } = Typography;

const QuestionBank = () => {
    const [formDataSelected, setFormData] = useState<QuestionProcessing | undefined>(undefined);
    const [answerLoading, setAnswerLoading] = useState(false);


    const onFinish = (formData: AnswerFormData) => {
        setAnswerLoading(true);
        console.log(formData);
        const userId = localStorage.getItem("userId");
        const answerRequest: AnswerRequest = {
            answeredBy: {
                id: userId,
            },
            content: formData.answer, // use the "answer" field as the answer content
            status: 3, // set status to 3 for published
            question: {
                id: formDataSelected?.id, // use the selected form data as the question ID
            },
        };

        SubmitAnswerToDatabase(answerRequest)
            .then((response) => {
                console.log(response);
                message.success("Answer submitted successfully");
                setEditing(false)
            })
            .catch((error) => {
                console.log(error)
                message.error("Failed to submit answer");
            })
            .finally(() => {
                getQuestionsForAsatizahReview()
                    .then((res) => {
                        if (res) {
                            console.log(res)
                            setData(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setAnswerLoading(false);
                setEditing(false)
            })


    }

    const AnswerComponent = () => {
        // const [textEditor, setEditing] = useState(false);
        const onChangeTextEditor = (e: any) => {

            console.log(e)
        }


        return (
            <div>
                <Button type="primary" onClick={() => setEditing(false)}> Back </Button>

                <Form initialValues={formDataSelected} onFinish={onFinish} size="large">
                    <Title level={3}>Question Title</Title>
                    <Form.Item name="title" rules={[{required: true}]}>
                        <TextArea rows={1} readOnly/>
                    </Form.Item>
                    <Title level={3}>Question Content</Title>
                    <Form.Item className="bg-white" name="content" rules={[{required: true}]}>
                        <TextArea
                            rows={2}
                            readOnly/>
                    </Form.Item>
                    <Title level={3}>Answer</Title>
                    <Form.Item className="bg-white" name="answer" rules={[{required: true}]}>
                        <TextEditor
                            value=""
                            placeholder=""
                            onChange={onChangeTextEditor}/>
                    </Form.Item>
                    <div className="float-right mt-2">
                        <Button type="primary" htmlType="submit" size="large" loading={answerLoading}>
                            Process
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }

    const columns: ColumnsType<QuestionProcessing> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '2%',
            render: (id) => <p>{id}</p>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (createdAt) => moment(createdAt).format('DD MMM YYYY, hh:mm A')
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            render: (_, record) => (
                <div className="grid grid-cols-3 gap-3">
                    {/*<div>*/}
                    <Button
                        type="primary"
                        onClick={() => handleAnswer(record)}
                    >
                        Answer
                    </Button>


                </div>

            ),
        },
    ];

    // const handleAnswer = (record: any) => {
    //     setEditing(true);
    // }

    const handleAnswer = (record: any) => {
        setFormData(record);
        setEditing(true);
    }

    const [data, setData] = useState<QuestionProcessing[]>([]); // Assume this is your table data

    useEffect(() => {
        getQuestionsForAsatizahReview()
            .then((res) => {
                if (res) {
                    console.log(res)
                    setData(res)
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    const [editing, setEditing] = useState(false);


    return (
        <AdminLayout>
            <div className="container mx-auto">
                {editing ? (
                    <AnswerComponent/>
                ) : (
                    <>
                        <p className="text-4xl font-bold underline underline-offset-8 decoration-secondary"> Question
                            Bank </p>
                        <br/>
                        <br/>
                        <Table
                            key={data.length}
                            columns={columns}
                            dataSource={data}
                            pagination={{pageSize: 8}} // set pageSize to 8 rows per page
                        />
                    </>

                )}

            </div>
        </AdminLayout>
    )
}

export default QuestionBank