import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Input} from 'antd';
import {getAllQuestions, getQuestionByStatusDuplicate} from "../../services/api/SnsAPI";
import {useNavigate} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";


interface QuestionTitleTagSearch {
    id: number;
    title: string;
}
const SearchBar: React.FC = () => {

    const [questionTitleData, setQuestionTitleData] = useState<QuestionTitleTagSearch[]>()

    useEffect(() => {
        getQuestionByStatusDuplicate()
            .then((data) => {
                if (data){
                    const questionsTitle: QuestionTitleTagSearch[] = data.map((question: any) => ({
                        id: question.id,
                        title: question.title,
                    }));
                    setQuestionTitleData(questionsTitle)
                } else {
                    console.log("Error")
                }
            }).catch((exception) => {
            console.log(exception);
        });
    }, []);
    const navigate = useNavigate();

    const handleSelect = (value: string, option: any) => {
        const selectedQuestionId = questionTitleData?.find(question => question.title === value)?.id;
        if (selectedQuestionId){
            navigate(`/viewQuestion/${selectedQuestionId}`)
        }
    }

    return (
        <>
            <AutoComplete
                className="pt-5 pb-10 w-3/5 drop-shadow-lg"
                popupClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                options={questionTitleData?.map((option) => ({ value: option.title }))}
                filterOption={true}
                onSelect={handleSelect}
                placeholder="Type and search for your questions here!!"
                size="large"
            >
            </AutoComplete>
            <Button type="primary" size="large" className="" icon={<span className="mb-0.5 mr-1.5"><SearchOutlined /></span>}>
                Search
            </Button>
        </>

    );

}

export default SearchBar;