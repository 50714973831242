import SideMenu from "../../../components/AdminSideMenu";
import 'react-quill/dist/quill.snow.css';
import {
    Button,
    Form,
    Input,
    Dropdown,
    Typography,
    MenuProps,
    Tag,
    Space,
    Table,
    message,
    Select,
    Modal,
    Breadcrumb, AutoComplete, Divider
} from "antd";
import {ArrowLeftOutlined, CloseOutlined, DownOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {Link,  useNavigate} from "react-router-dom";
import AdminLayout from "../../../Layout/AdminLayout";
import {ColumnsType} from "antd/es/table";
import TextEditor from "../../../components/TextEditor";
import TextArea from "antd/es/input/TextArea";
import { ClearOutlined, SearchOutlined, FilterOutlined, EyeOutlined } from '@ant-design/icons';

import {
    duplicateQuestionEndpoint, DuplicateQuestionRequest,
    getAllCategoryWithoutNotSure,
    getAllQuestions, getAllTags, getQuestionByStatusDuplicate,
    getQuestionByStatusProcessing,
    QuestionProcessing, QuestionProcessingPublished,
    updateQuestionDetailsForAdminProcessing, updateQuestionStatusRejected
} from "../../../services/api/SnsAPI";
import moment from "moment";

const { Title, Text } = Typography;
const {Search}=Input;

const QuestionReview = (props:any) => {


    const ProcessComponent = () => {

        const [loadingProcess, setLoadingProcess] = useState(false);


        interface Category {
            name:string;
            id:number;
        }
        interface Tag {
            name:string;
            id:number;
            color:string;
        }
        const removeIcon = () => <CloseOutlined style={{ color: "black" }} />;
        const [categoryOptions, setCategoryOptions] = useState<Category[] | void>([]);
        const [tagsOptions, setTagsOptions] = useState<Tag[] | void>([]);

        useEffect(() => {
            //      Call Category and tags for all.
            getAllCategoryWithoutNotSure()
                .then((res) => {
                    setCategoryOptions(res)
                })
            getAllTags()
                .then((res) => {
                    setTagsOptions(res)
                })

        },[])

        interface QuestionProcessing {
            id: number ;
            title: string;
            content: string;
            category: { id: number };
            tags: { id: number, name: string; color:string; }[];
            status: number;
        }

        interface formDataForProcessing {
            title: string;
            content: string;
            category: number;
            tags: number[];
        }

        const onFinishProcessing = (formData: formDataForProcessing) => {
            console.log("On finish")
            console.log(formData)

            setLoadingProcess(true);
            const findTagById = (id: number): Tag | undefined => {
                // @ts-ignore
                return tagsOptions && tagsOptions.find((tag) => tag.id === id);
            };

            // Map the tags IDs to the full tag objects
            const tagsWithDetails = formData.tags
                .map((tagId) => {
                    const tag = findTagById(tagId);
                    return tag ? { id: tag.id, name: tag.name, color: tag.color } : undefined;
                })
                .filter((tag) => tag !== undefined) as { id: number; name: string; color: string }[];



            // @ts-ignore
            const id = formDataSelected.id;


            const data: QuestionProcessing = {
                id: id,
                title: formData.title,
                content: formData.content,
                category: { id: formData.category  },
                tags: tagsWithDetails,
                status: 1
            }
            console.log("This is data ----- ")
            console.log(data)

            updateQuestionDetailsForAdminProcessing(data)
                .then(response => {
                    console.log(response)
                    message.success("Question updated to processed successfully")
                    getQuestionByStatusProcessing()
                        .then((res) => {
                            if(res){
                                console.log(res)
                                setData(res)
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setEditing(false)
                })
                .catch(error => {
                    console.log(error)
                    message.error("Failed to update question")
                })
                .finally(() => {
                    setLoadingProcess(false);

                });
        }




        return (
            <Form initialValues={
                {
                    title:formDataSelected?.title,
                    category:formDataSelected?.category.id,
                    content:formDataSelected?.content,
                }}  onFinish={onFinishProcessing} size="large"  >
                <Button type="primary" onClick={() => setEditing(false)}> Back </Button>
                <Title level={3}>Question Title</Title>
                <Form.Item name="title" rules={[{ required: true }]} >
                    <TextArea rows={2} />
                </Form.Item>

                <div className="grid grid-cols-2 gap-8">

                    <div>
                        <Title level={3}>Category</Title>
                        <Form.Item name="category" rules={[{ required: true }]}>
                            <Select>
                                { categoryOptions?.map( (category) => {
                                    // Check if categoryId is not 1 before displaying the option
                                    if (category.id !== 1) {
                                        return (
                                            <Select.Option key={category.id} value={category.id}>
                                                {category.name}
                                            </Select.Option>
                                        );
                                    }
                                    return null; // Return null if categoryId is 1
                                })
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div>
                        <Title level={3}>Tags</Title>

                        <Form.Item name="tags" rules={[{ required: true }]}>
                            <Select
                                mode="multiple"
                                removeIcon={removeIcon}
                            >
                                { tagsOptions?.map( (tag) => {
                                    return (
                                        <Select.Option key={tag.id} value={tag.id}>
                                            <Tag color={tag.color}>
                                                {tag.name}
                                            </Tag>
                                        </Select.Option>
                                    )
                                })}

                            </Select>
                            {/*<Input />*/}
                        </Form.Item>
                    </div>

                </div>


                <Title level={3}>Question Content</Title>
                <Form.Item className="bg-white" name="content" rules={[{ required: true }]}>
                    <TextArea

                        rows={4} />
                    {/*<TextEditor*/}
                    {/*    value=""*/}
                    {/*    placeholder=""*/}
                    {/*    onChange={onChangeTextEditor}/>*/}
                </Form.Item>

                <br/>

                <div className="float-right">
                    <Button type="primary" htmlType="submit" size="large" loading={loadingProcess}>
                        Process
                    </Button>
                </div>
            </Form>
        );
    };

    const DuplicateComponent = () => {

        const [duplicateLoading, setDuplicateLoading] = useState(false);


        interface Category {
            name:string;
            id:number;
        }
        interface Tag {
            name:string;
            id:number;
            color:string;
        }
        const removeIcon = () => <CloseOutlined style={{ color: "black" }} />;
        const [categoryOptions, setCategoryOptions] = useState<Category[] | void>([]);
        const [tagsOptions, setTagsOptions] = useState<Tag[] | void>([]);

        useEffect(() => {
            //      Call Category and tags for all.
            getAllCategoryWithoutNotSure()
                .then((res) => {
                    setCategoryOptions(res)
                })
            getAllTags()
                .then((res) => {
                    setTagsOptions(res)
                })

        },[])



        interface DuplicateQuestionFormData {

        }

        const duplicateQuestionHandler = ( formData: DuplicateQuestionFormData ) => {
            setDuplicateLoading(true);
            console.log("Rejecting Question");
            try {
                console.log("Logging values");
                console.log(formDataSelected)
                const userId = localStorage.getItem("userId")
                if (formDataSelected === undefined) {
                    return {}
                }
                // @ts-ignore
                const transformedData: DuplicateQuestionRequest | undefined  = {
                    userId: userId,
                    duplicateQuestionId: selectedQuestionId,
                    currentQuestionId: formDataSelected.id
                };

                duplicateQuestionEndpoint(transformedData)
                    .then((res) => {
                        console.log(res)
                        message.success("Successfully updated");
                        getQuestionByStatusProcessing()
                            .then((res) => {
                                if(res){
                                    console.log(res)
                                    setData(res)
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        setEditing(false);
                    })
                    .catch((exception: Error) => {
                        console.log(exception.message)
                        message.error(exception.message)
                    })
                    .finally(() => {
                        setDuplicateLoading(false);
                    });

            } catch (error) {
                message.error("Error updating data");
                setDuplicateLoading(false);
            }
            // setEditing(false);
        }



        const [questionTitleData, setQuestionTitleData] = useState<QuestionProcessingPublished[]>()
        useEffect(() => {
            getQuestionByStatusDuplicate()
                .then((data) => {
                    if (data){
                        const questionsTitle: QuestionProcessingPublished[] = data
                        setQuestionTitleData(questionsTitle)
                    } else {
                        console.log("Error")
                    }
                }).catch((exception) => {
                console.log(exception);
            });
        }, []);

        // interface QuestionProcessing {
        //     id: number;
        //     title: string;
        //     createdAt: Date;
        //     content: string;
        //     status: string;
        //     source: string;
        //     category: string;
        //     tags: string;
        // }

        const filterExistingHandler = (filteredCategoryId:any, filteredTagsId:any ) => {
            setEnterLoadingFilterButton(true)
            // setTimeout(() => {
            //     setEnterLoadingFilterButton(false);
            // }, 4000);

            console.log("Logging ")
            console.log(filteredCategoryId)
            console.log(filteredTagsId)

            getQuestionByStatusDuplicate()
                .then((data) => {
                    if (data) {
                        const questionsTitle: QuestionProcessingPublished[] = data;
                        let filteredQuestions = [...questionsTitle]; // create a copy of the original data
                        console.log("Logging filteredQuestions")
                        console.log(filteredQuestions)

                        // filter by category
                        if (filteredCategoryId) {
                            filteredQuestions = filteredQuestions.filter(question => question.category.id == filteredCategoryId);
                        }
                        console.log("Logging after category")
                        console.log(filteredQuestions)

                        console.log("Filtering these tags")
                        console.log(filteredTagsId)
                        // console.log(question)


                        // filter by tags
                        if (filteredTagsId && filteredTagsId.length > 0) {
                            filteredQuestions = filteredQuestions.filter(question => {
                                const questionTags = question.tags.map(tag => tag.id);
                                console.log("Logging questionTags");
                                console.log(questionTags);
                                return filteredTagsId.every(  (tagId: string)  => { // Change this line
                                    console.log(questionTags);
                                    console.log(tagId); // Change this line
                                    return questionTags.includes(parseInt(tagId, 10)); // Change this line
                                });
                            });
                        }

                        console.log("Logging after tags")
                        console.log(filteredQuestions)

                        setQuestionTitleData(filteredQuestions);
                        message.success("Questions has been filtered");
                    } else {
                        console.log("Error");
                    }
                }).catch((exception) => {
                console.log(exception);
            });
            setEnterLoadingFilterButton(false)


        }
        // const [selectedQuestionId, setSelectedQuestionId] = useState<number | undefined>();
        const handleSelect = (value: string, option: any) => {
            setSelectedQuestionId(questionTitleData?.find(question => question.title === value)?.id);
            setSelectedQuestionData(questionTitleData?.find(question => question.title === value));
        }

        const [selectedCategory, setSelectedCategory] = useState<string>("");
        const [selectedTags, setSelectedTags] = useState<string[]>([]);
        const [selectedQuestionId, setSelectedQuestionId] = useState<number | undefined>();
        const [selectedQuestionData, setSelectedQuestionData] = useState<QuestionProcessingPublished>();


        const clearFilterHandler = () => {
            getQuestionByStatusDuplicate()
                .then((data) => {
                    if (data){
                        const questionsTitle: QuestionProcessingPublished[] = data
                        setQuestionTitleData(questionsTitle)
                    } else {
                        console.log("Error")
                    }
                }).catch((exception) => {
                console.log(exception);
            });
        }

        const openQuestionInNewTab = () => {
            const FrontendURL = process.env.REACT_APP_FRONTEND_URL;
            console.log(FrontendURL)
            if (selectedQuestionId) {
                const url = FrontendURL + `/viewQuestion/${selectedQuestionId}`; // Replace this with the correct URL pattern
                console.log(url)
                window.open(url, "_blank");
            } else {
                message.error("Error: No question selected.");
            }
        };

        const [enterLoadingFilterButton, setEnterLoadingFilterButton] = useState<boolean>(false);

        return (

            <div>
                <Button type="primary" onClick={() => setEditing(false)}> Back </Button>
                <br/>
                <br/>

                <Title level={2} className="mx-auto">Selected Question: {formDataSelected?.title}</Title>
                <div>
                    <Form
                        // initialValues={formDataSelected}
                        onFinish={onFinish} size="large">
                        <br />
                        {/*<Title level={2}>Find Duplicate Question</Title>*/}
                        <div className="grid grid-cols-11 gap-8">
                            {/* Left side */}
                            <div className="col-span-3">
                                {/* Category */}
                                <Form.Item name="category" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Filter by category"
                                        onChange={ (value) => setSelectedCategory(value)}
                                    >
                                        {categoryOptions?.map((category) => (
                                            <Select.Option key={category.id} value={category.id}>
                                                {category.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* Tags */}
                                <Form.Item name="tags" rules={[{ required: true }]}>
                                    <Select
                                        mode="multiple"
                                        removeIcon={removeIcon}
                                        placeholder="Filter by Tags"
                                        onChange={ (value) => setSelectedTags(value)}
                                    >
                                        {tagsOptions?.map((tag) => (
                                            <Select.Option key={tag.id} value={tag.id}>
                                                <Tag color={tag.color}>{tag.name}</Tag>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* Filter button */}
                                <Button
                                    type="primary"
                                    className="w-full"
                                    onClick={() => filterExistingHandler(selectedCategory, selectedTags)}
                                    block
                                    loading={enterLoadingFilterButton}
                                    icon={<span className="mb-1 mr-1.5"><FilterOutlined/></span>}
                                >
                                    Filter Search
                                </Button>


                            </div>

                            {/* Vertical divider */}
                            <Divider className="col-span-1 border-slate-900 h-full" type="vertical" />

                            {/* Right side */}
                            <div className="col-span-7">
                                <div className="flex flex-row ">
                                    {/* AutoComplete */}
                                    <div className="w-3/4">
                                        <AutoComplete
                                            className="w-full drop-shadow-sm mx-auto"
                                            popupClassName="certain-category-search-dropdown"
                                            dropdownMatchSelectWidth={500}
                                            options={questionTitleData?.map((option) => ({ value: option.title }))}
                                            filterOption={true}
                                            onSelect={handleSelect}
                                            allowClear={true}
                                            placeholder="Search the duplicated question here"
                                        />
                                    </div>

                                    {/* Preview Question button */}
                                    <Button type="primary" onClick={openQuestionInNewTab} className="ml-4" icon={<span className="mb-1 mr-1.5"><EyeOutlined/></span>}>
                                        Preview
                                    </Button>

                                    <Button
                                        type="primary"
                                        className=" ml-4"
                                        onClick={clearFilterHandler}
                                        icon={<span className="mb-1 mr-1.5"><ClearOutlined /></span>}
                                    >
                                        Clear Filter
                                    </Button>


                                </div>
                                <br/>

                                <Title level={4} className="">Selected Question:  <Text> {selectedQuestionData?.title} </Text> </Title>
                                <Text> {selectedQuestionData?.content} </Text>
                            </div>

                        </div>

                        {/* Horizontal divider */}
                        <Divider className="border-slate-900" />

                        {/* Confirm Duplicate Question button */}
                        <div className="float-right">
                            <Button type="primary" onClick={duplicateQuestionHandler} loading={duplicateLoading}>
                                Duplicate Question
                            </Button>
                        </div>

                    </Form>
                </div>


            </div>
        );
    };

    const RejectComponent = () => {

        const [rejectLoading, setRejectLoading] = useState(false);

        const transformFormDataToApiPut = (formData: FormData): object => {
            // Just to stop the error below
            if (formDataSelected === undefined) {
                return {}
            }
            const transformedData = {
                id: formDataSelected.id,
                tags: formData.tags.map(tagId => ({ id: tagId })),
                category: { id: formData.category },
                title: formData.question,
                content: formData.content,
                status: 1,
            };

            return transformedData;
        };

        const RejectingQuestionHandler = () => {
            setRejectLoading(true);
            console.log("Rejecting Question");
            try {
                console.log("Logging values");
                console.log(formDataSelected)
                if (formDataSelected === undefined) {
                    return {}
                }
                const transformedData = {
                    id: formDataSelected.id,
                    title: formDataSelected.title,
                    content: formDataSelected.content,
                    status: 3,
                    // TODO: Proper endpoint for Later remove this
                    tags: [{id: 1}],
                    category: {id:1},
                    source: formDataSelected.source
                };
                console.log("Transformed Data")
                console.log(transformedData)

                updateQuestionStatusRejected(transformedData)
                    .then((response) => {
                        console.log(response)
                        getQuestionByStatusProcessing()
                            .then((res) => {
                                if(res){
                                    console.log("res")
                                    console.log(res)
                                    // setEditing(false);
                                    setData(res)
                                    // setEditing(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        setEditing(false);
                        message.success("Successfully updated");
                    })
                    .catch((error) => {
                        console.log(error)
                        message.error("Error updating data");
                    })
                    .finally(() => {
                        setRejectLoading(false); // Set the loading state to false when the API call is done
                    });
            } catch (error) {
                console.log(error)
                setRejectLoading(false);
            }
        }

        return (
            <div>
                <Form initialValues={formDataSelected}  onFinish={onFinish} size="large">
                    <Button type="primary" onClick={() => setEditing(false)}> Back </Button>
                    <br/>
                    <br/>
                    <Title level={3}>Question Title</Title>
                    <Form.Item name="title" rules={[{ required: true }]} >
                        <TextArea rows={2} />
                    </Form.Item>
                    <Title level={3}>Question Content</Title>
                    <Form.Item className="bg-white" name="content" rules={[{ required: true }]}>
                        <TextArea
                            rows={4} />
                    </Form.Item>
                    <br/>
                    <Button type="primary" onClick={RejectingQuestionHandler} icon={<span className="mb-1 mr-1"><CloseOutlined /></span>} danger block loading={rejectLoading}>
                        Reject
                    </Button>
                </Form>
            </div>
        );
    };



    const [data, setData] = useState<QuestionProcessing[]>([]); // Assume this is your table data
    const [editing, setEditing] = useState(false);
    const [formDataSelected, setFormData] = useState<QuestionProcessing | undefined>(undefined);
    const columns: ColumnsType<QuestionProcessing> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width:'2%',
            render: (id) => <p>{id}</p>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width:'50%',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'15%',
            render: (createdAt) => moment(createdAt).format('DD MMM YYYY, hh:mm A')
        },
        {
            title: 'Action',
            key: 'action',
            width:'20%',
            render: (_, record:QuestionProcessing) => (
                <div className="grid grid-cols-3 gap-3">
                    {/*<div>*/}
                        <Button
                            type="primary"
                            onClick={() => handleProcess(record)}
                        >
                            Process
                        </Button>
                    {/*</div>*/}

                    {/*<div>*/}
                        <Button
                            type="primary"
                            // className="bg-[#67B9BB]"
                            style={{ backgroundColor: "#67B9BB"}}
                            onClick={() => handleDuplicate(record)}

                        >
                            Duplicate
                        </Button>
                    {/*</div>*/}

                    {/*<div>*/}
                        <Button
                            type="primary"
                            onClick={() => handleReject(record)}
                            danger
                        >
                            Reject
                        </Button>
                    {/*</div>*/}

                </div>

            ),
        },
    ];
    const [actionType, setActionType] = useState<string | null>(null);

    const handleProcess = (record: QuestionProcessing) => {
        console.log("Record ")
        console.log(record)
        setEditing(true);
        setFormData(record);
        setActionType("process");
    };

    const handleDuplicate = (record: QuestionProcessing) => {
        setEditing(true);
        setFormData(record);
        setActionType("duplicate");
    };

    const handleReject = (record: QuestionProcessing) => {
        setEditing(true);
        setFormData(record);
        // warning();
        setActionType("reject");
    };




    useEffect(() => {
        getQuestionByStatusProcessing()
            .then((res) => {
                if(res){
                    console.log(res)
                    setData(res)
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);


    const [loadings, setLoadings] = useState<boolean>(false);
    interface FormData {
        question: string;
        category: string;
        tags: string[];
        content: string;
    }

    const transformFormDataToApiPut = (formData: FormData): object => {
        // Just to stop the error below
        if (formDataSelected === undefined) {
            return {}
        }
        const transformedData = {
            id: formDataSelected.id,
            tags: formData.tags.map(tagId => ({ id: tagId })),
            category: { id: formData.category },
            title: formData.question,
            content: formData.content,
            status: 1,
        };

        return transformedData;
    };

    const onFinish = (values: FormData) => {
        // Update the data and show success/error message
        try {

            console.log("Logging values");
            console.log(values)
            const transformedData = transformFormDataToApiPut(values);
            console.log(transformedData)
            // Update the data (you can replace this with API call)
            // const updatedData = data.map((item) => (item.key === formData.key ? { ...item, ...values } : item));
            // setData(updatedData);

            message.success("Successfully updated");
        } catch (error) {
            message.error("Error updating data");
        }

        setEditing(false);
    };

    const onChangeTextEditor = (errorInfo: any) => {
        console.log("Change")
        console.log(errorInfo)
    }
    const removeIcon = () => <CloseOutlined style={{ color: "black" }} />;

    return (
        <AdminLayout>
            <div className="container mx-auto  overflow-hidden">
                <br/><br/>
                {editing ? (
                    actionType === "process" ? (
                        <ProcessComponent />
                    ) : actionType === "duplicate" ? (
                        <DuplicateComponent />
                    ) : (
                        <RejectComponent />
                    )
                ) : (
                    <>
                        <p className="text-4xl font-bold underline underline-offset-8 decoration-secondary"> Question Review </p>
                        <br/>
                        <Table
                            key={data.length}
                            columns={columns}
                            dataSource={data}
                            pagination={{ pageSize: 8 }} // set pageSize to 8 rows per page
                        />
                    </>
                )}


            </div>
        </AdminLayout>
    )
}

export default QuestionReview












// const handleMenuClick: MenuProps['onClick'] = (e) => {
//     console.log('click', e);
//   };
//
// const items: MenuProps['items'] = [
//     {
//         label: '1st menu item',
//         key: '1',
//     },
//     {
//         label: '2nd menu item',
//         key: '2',
//     },
//     {
//         label: '3rd menu item',
//         key: '3',
//     },
//     {
//         label: '4rd menu item',
//         key: '4',
//     },
// ];

interface IPostCreate {
    body: string;
}
//
// const menuProps = {
//     items,
//     onClick: handleMenuClick,
// };

//<div className="flex flex-row mt-5 mb-10 text-center">
//                         <div className="basis-1/3 items-center">
//                             <Button onClick={() => goBack()} type="primary" danger className="float-left" size={"large"} loading={loadings} icon={<ArrowLeftOutlined/>}> Back </Button>
//                         </div>
//                         <div className="basis-1/3">
//                             <p className="text-4xl font-bold underline underline-offset-8 decoration-secondary"> To Be Categorised </p>
//                         </div>
//                         <div className="basis-1/3">
//                             <Button type="primary" className="float-right bg-secondary" size={"large"} loading={loadings} htmlType="submit">
//                                 Save
//                             </Button>
//                         </div>
//                     </div>
//                     <div className="grid grid-cols-6 gap-10">
//                         <div className="col-span-2">
//                             <SideMenu />
//                         </div>
//                         <div className="col-span-4">
//                             <div className="mb-5">
//                                 <Title level={3}>Title</Title>
//                                 <Input size="large" placeholder="Question Title" />
//                             </div>
//                             <div className="mb-5">
//                                 <Title level={3}>Question</Title>
//                                 <Input size="large" placeholder="Question" />
//                             </div>
//                             <div className="mb-5">
//                                 <Title level={3}>Category</Title>
//                                 <Dropdown menu={menuProps}>
//                                     <Button className="dropdownMenu">
//                                         List of Categories
//                                         <DownOutlined />
//                                     </Button>
//                                 </Dropdown>
//                             </div>
//                             <div className="mb-5">
//                                 <Title level={3}>URL Link to Answer</Title>
//                                 <Input size="large" placeholder="URL Link" />
//                             </div>
//                         </div>