import BasicTenentsIcon from "../../assets/CategoryIcons/basic-tenets.png";
import QuranIcon from "../../assets/CategoryIcons/quran.png";
import HadithIcon from "../../assets/CategoryIcons/hadith.png";
import SocialIcon from "../../assets/CategoryIcons/social.png";
import AskatizahLogo from "../../assets/askatizah_logo.png";
import Icon, {HomeOutlined, EditOutlined, InboxOutlined, CheckCircleOutlined} from "@ant-design/icons";
export default function AdminSideMenu() {
    return (
        <div className="p-3 bg-slate-900 sidemenu">



            <div className="space-y-3"> 
                <div className="flex-1">
                    {/*<div className="mt-24">*/}
                        <img src={AskatizahLogo} className="h-20 w-25 mx-auto" alt="logo"/>
                    {/*</div>*/}
                    <br/>
                    <br/>
                    <ul className="pt-2 pb-4 space-y-1 text-sm">
                        <li className="rounded-sm">
                            <a href="/adminDashboard" className="flex items-center p-2 space-x-3 text-white">
                                <HomeOutlined className="text-lg mt-1"/>

                                {/*<img className="w-6 imgWhite" src={BasicTenentsIcon} alt="basic-icon"/>*/}
                                <span>Home Dashboard</span>
                            </a>
                        </li>
                        <li className="rounded-sm">
                            <a href="/questionReview" className="flex items-center p-2 space-x-3 text-white">
                                <EditOutlined className="text-lg mt-1"/>
                                {/*<img className="w-6 imgWhite" src={QuranIcon} alt="quran-icon"/>*/}
                                <span>Question Review</span>
                            </a>
                        </li>
                        <li className="rounded-sm">
                            <a href="/questionBank" className="flex items-center p-2 space-x-3 text-white">
                                {/*<img className="w-6 imgWhite" src={HadithIcon} alt="hadith-icon"/>*/}
                                <InboxOutlined className="text-lg mt-1" />
                                <span>Question Bank</span>
                            </a>
                        </li>
                        <li className="rounded-sm">
                            <a href="/publishedQuestions" className="flex items-center p-2 space-x-3 text-white">
                                {/*<img className="w-6 imgWhite" src={SocialIcon} alt="social-icon"/>*/}
                                <CheckCircleOutlined className="text-lg mt-1" />
                                <span>Published Questions</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}