import {Button, Card, Col, Form, Input, message, Row, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import AskatizahLogo from "../../../assets/askatizah_logo.png";
import {AuthenticateLogin, UserResponse} from "../../../services/api/SnsAPI";
import {
    HomeOutlined,
    LoginOutlined
} from '@ant-design/icons';

const onFinish = (values: any) => {
    console.log('Success:', values);
};
  
const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
};


interface UserDetailFormData {
    username: string;
    password: string;
}


const {Title} = Typography;

const AdminLogin = () => {
    const navigate = useNavigate();

    const authenticateUser = (formData: UserDetailFormData) => {
        console.log("Authenticating user")
        console.log(formData);

        AuthenticateLogin(formData)
            .then((allUserDetails: UserResponse | void) => {
                if (allUserDetails){
                    localStorage.setItem('userId', allUserDetails.id);
                    localStorage.setItem('name', allUserDetails.name);
                    localStorage.setItem('role', allUserDetails.role);
                    navigate("/adminDashboard");
                } else{
                    message.error("Invalid username or password")
                }
            })
            .catch((error) => {
                message.error("Invalid username or password")
            });



    }

    return (
        <>
        {/*<Row align={"middle"} justify={"space-between"}>*/}
        {/*    <Col xs={2} sm={4} md={6} lg={8}></Col>*/}
        {/*    <Col xs={20} sm={16} md={12} lg={8}>*/}
            <div className="flex items-center justify-center min-h-screen">
                <Card className="drop-shadow-lg mx-auto mb-52 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-2/7">
                    {/*<Link to={"/"}>*/}
                    <div className="ml-2">
                        <Link to="/">
                            {/*<span className="w-8 h-8">*/}
                            <HomeOutlined style={{ fontSize: '38px' }}  />
                            {/*</span>*/}

                            {/*<Button type="primary" className="bg-secondary" size="large" icon={<span className="mr-1"><HomeOutlined /></span>}>*/}
                            {/*    Home*/}
                            {/*</Button>*/}
                        </Link>
                    </div>


                    <img
                        src={AskatizahLogo}
                        className="mx-auto w-32 h-32 sm:w-48 sm:h-48 md:w-60 md:h-60 align-center pb-10 mt-10"
                        alt="logo"
                    />
                    {/*</Link>*/}
                    {/*<p className="text-4xl font-bold text-center pb-5"> Admin Login </p>*/}
                    <Form
                        // labelCol={{ span: 6 }}
                        // wrapperCol={{ span: 14 }}
                        onFinish={authenticateUser}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className=""
                    >
                        <div className="flex flex-row mb-2 justify-center">
                            <Title level={4} className="w-1/6">
                                Email
                            </Title>
                            <Form.Item
                                className="w-4/6"
                                name="email"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input className="w-full" placeholder="Key in Email" size="large" />
                            </Form.Item>
                        </div>

                        <div className="flex flex-row justify-center">
                            <Title level={4} className="w-1/6">
                                Password
                            </Title>
                            <Form.Item
                                className="w-4/6"
                                name="password"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input.Password placeholder="Key in password" size="large" />
                            </Form.Item>
                        </div>

                        <Form.Item className="pt-3">
                            <div className="flex justify-center">
                                <Button type="primary" className="bg-secondary w-5/6" htmlType="submit" size="large"  icon={<span className="mr-1"><LoginOutlined /></span>}>
                                    Login
                                </Button>
                            </div>

                        </Form.Item>
                    </Form>
                </Card>
            </div>

            {/*    </Col>*/}
        {/*    <Col xs={2} sm={4} md={6} lg={8}></Col>*/}
        {/*</Row>*/}
        </>
    )
}

export default AdminLogin