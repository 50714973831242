import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import StyledHeader from "../../components/StyledHeader";

const ErrorPage = () => {


    return (
        <>
            <StyledHeader/>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" className="bg-secondary float-right" > <Link to="/">Back Home</Link></Button>}
            />

        </>

    )
}

export default ErrorPage