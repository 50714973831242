import AdminSideMenu from "../../../components/AdminSideMenu";
import 'react-quill/dist/quill.snow.css';
import { Space, Table, Tag, Button, Card, Statistic } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AdminLayout from "../../../Layout/AdminLayout";
import React, {useEffect, useState} from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    getAllCategoryWithoutNotSure,
    getQuestionByStatusProcessing,
    getQuestionsForAsatizahReview, QuestionProcessing
} from "../../../services/api/SnsAPI";
import moment from "moment/moment";
import TelegramLogo from "../../../assets/telegram.png"
import MessengerLogo from "../../../assets/messenger.png"
import WebsiteLogo from "../../../assets/website.png"


const columns: ColumnsType<QuestionProcessing> = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width:'2%',
        render: (id) => <p className="font-medium">{id}</p>,
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width:'50%',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width:'15%',
        render: (createdAt) => moment(createdAt).format('DD MMM YYYY, hh:mm A'),
        sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width:'20%',
        render: (_, record) => (
            <div className="flex items-center">
                <img src={record.source === 'Telegram' ? TelegramLogo : record.source === 'Messenger' ? MessengerLogo : WebsiteLogo} alt="source" className="w-6 h-6"/>
                <p className="ml-1">{record.source}</p>
            </div>
        ),
        filters: [
            { text: 'Telegram', value: 'Telegram' },
            { text: 'Messenger', value: 'Messenger' },
            { text: 'Website', value: 'Website' },
        ],
        onFilter: (value: string | number | boolean, record) => record.source === value,
    },
];


const AdminDashboard = () => {

    const [questionPendingNumber, setQuestionPendingNumber] = useState(0)
    const [questionUnansweredNumber, setQuestionUnansweredNumber] = useState(0)
    const [questionSubmitedList, setQuestionSubmittedList] = useState<QuestionProcessing[]>([])

    useEffect(() => {

        // User just submit --> Give admin
        getQuestionByStatusProcessing()
            .then( (questionPendingList: any) => {
                const questionPendingLength = questionPendingList.length;
                console.log("Length of questionPendingList:", questionPendingLength);
                setQuestionPendingNumber(questionPendingLength)
                setQuestionSubmittedList(questionPendingList)
            })
            .catch((err:any) => {
                console.log(err)
            })

        // Admin just processed --> Give asatizah
        getQuestionsForAsatizahReview()
            .then((questionProcessedList: any) => {
                const questionProcessedLength = questionProcessedList.length;
                console.log("Length of questionProcessedList:", questionProcessedLength);
                setQuestionUnansweredNumber(questionProcessedLength)
            })
            .catch((err:any) => {
                console.log(err)
            })

    },[])

    return (
            <AdminLayout>
                <div className="container mx-auto">

                    <div className="grid grid-cols-3 gap-8">

                        <div className="">
                            <Card className="font-bold shadow-xl text-center" >
                                <Statistic
                                    title={<span className="text-black text-base">Questions Pending Review</span>}
                                    value={questionPendingNumber}
                                    precision={0}
                                    className="text-cyan-500"
                                    valueStyle={{ color: '#67B9BB', fontSize: 50 }}
                                    // prefix={<QuestionCircleOutlined />}
                                    suffix=""
                                />

                            </Card>
                        </div>
                        <div className="">
                            <Card className="font-bold shadow-xl text-center" >
                                <Statistic
                                    title={<span className="text-black text-base">Questions Unanswered</span>}
                                    value={questionUnansweredNumber}
                                    precision={0}
                                    className="text-cyan-500"
                                    valueStyle={{ color: '#cf1322', fontSize: 50 }}
                                    // prefix={<QuestionCircleOutlined />}
                                    suffix=""
                                />
                            </Card>

                        </div>
                    </div>

                    <div className="mt-10">
                        <p className="text-2xl font-bold underline underline-offset-8 decoration-secondary mb-4"> Newest Questions </p>
                        <Table
                            key={questionSubmitedList.length}
                            columns={columns}
                            dataSource={questionSubmitedList}
                            pagination={{pageSize: 6}}
                        />
                    </div>


                </div>
            </AdminLayout>
    )
}

export default AdminDashboard

//  UNUSED CODE
{/*    <div className="flex flex-row mt-5 mb-10 text-center">*/}
{/*        <div className="basis-1/3 items-center"></div>*/}
{/*        <div className="basis-1/3 mr-20">*/}
{/*            <Card className="font-bold shadow-xl" style={{fontSize: 30}}>*/}
{/*                <p style={{fontSize: 50}}>200</p>*/}
{/*                <p style={{color: "#447C8D"}}>Questions To Be Categorised</p>*/}
{/*            </Card>*/}
{/*        </div>*/}
{/*        <div className="basis-1/3">*/}
{/*            <Card className="font-bold shadow-xl" style={{fontSize: 30}}>*/}
{/*                <p style={{fontSize: 50}}>200</p>*/}
{/*                <p style={{color: "#870000"}}>Questions To Be Categorised</p>*/}
{/*            </Card>*/}
{/*        </div>*/}
{/*    </div>*/}
{/*    <div className="grid grid-cols-6 gap-10">*/}
{/*        /!*<div className="col-span-2">*!/*/}
{/*        /!*    <SideMenu />*!/*/}
{/*        /!*</div>*!/*/}
{/*        <div className="col-span-4">*/}
{/*            <p className="text-4xl text-center font-bold underline underline-offset-8 decoration-secondary"> Newest Questions </p>*/}
{/*            <Table className="mt-5" columns={columns} dataSource={data} />*/}
{/*        </div>*/}
{/*    </div>*/}
{/*</div>*/}