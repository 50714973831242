import StyledHeader from "../../components/StyledHeader";
import {Button, Card, Tag, Typography} from "antd";
import BasicTenentsIcon from "../../assets/CategoryIcons/basic-tenets.png";
import QuranIcon from "../../assets/CategoryIcons/quran.png";
import HadithIcon from "../../assets/CategoryIcons/hadith.png";
import SocialIcon from "../../assets/CategoryIcons/social.png";
import FiqhIcon from "../../assets/CategoryIcons/fiqh.png";
import SeerahIcon from "../../assets/CategoryIcons/seerah.png";
import {Link, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import { stringify } from "json5";
import {
    CategoryType,
    getAllQuestions,
    getCategoriesApi, getQuestionByStatusDuplicate,
    getQuestionsByCategoryId, getQuestionsForPublishedQuestions,
    QuestionCardInfo
} from "../../services/api/SnsAPI";
import {CategoryWithImage} from "../../../types/api/categoryTypes";
import StyledTag from "../../components/StyledTag";
import {ClearOutlined} from "@ant-design/icons";

// interface Question {
//     id: number;
//     tags: { id: number; name: string }[];
//     category: { id: number; name: string };
//     title: string;
//     content: string;
// }



const Category = () => {

    const navigate = useNavigate();
    const [questionCard, setQuestionCard] = useState<QuestionCardInfo[]>([]);
    const [categoryFilterCard, setCategoriesCard] = useState<CategoryWithImage[]>([]);

    useEffect(() => {
        getCategoriesApi()
            .then((categories) => {
                if(categories){
                    setCategoriesCard(categories);
                    console.log(categories);
                } else {
                    console.log("Categories is empty");
                }
            })
            .catch((exception) => {
                console.log(exception);
            });
    }, []);

    useEffect(() => {
        getQuestionByStatusDuplicate()
            .then((response: QuestionCardInfo[] | void) => {
                console.log(response)
                if (response){
                    setQuestionCard(response);
                } else {
                    console.log("Error")
                }
            }).catch((exception) => {
            console.log(exception);
        });
    }, []);



    const CategoryFilterCards = () => {

        const clearFilter = () => {
            getQuestionByStatusDuplicate()
                .then((response: QuestionCardInfo[] | void) => {
                    console.log(response)
                    if (response){
                        setQuestionCard(response);
                    } else {
                        console.log("Error")
                    }
                }).catch((exception) => {
                console.log(exception);
            });
        }

        const handleCategoryClick = async (categoryId: number) => {
            getQuestionsByCategoryId(categoryId)
                .then((response) => {
                    if (response){
                        console.log("latest")
                        console.log(response)
                        setQuestionCard(response);
                    } else {
                        console.log("Error")
                    }
                }).catch((exception) => {
                console.log(exception);
            });
        };

        return (
            <>
                <Button type="primary" className="bg-secondary mb-2" onClick={clearFilter} block icon={<span className="mb-1 mr-1"><ClearOutlined /></span>}> Clear Filter </Button>
                {categoryFilterCard.map((category: CategoryWithImage) => {
                    return (
                        <div key={category.id}>
                            <Card bordered={true} className="mb-2 drop-shadow-lg">
                                <div className="grid grid-cols-3">
                                    <div>
                                        <img src={category.iconUrl} alt={`${category.name} icon`} className="w-10"/>
                                    </div>
                                    <div>
                                        <p className="font-medium text-base"> {category.name} </p>
                                    </div>
                                    <div className="text-right">
                                        <Button type="primary" className="bg-secondary" onClick={() => handleCategoryClick(category.id)} > View  </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                })}
            </>
        );
    };

    const QuestionCard = () => {
        return (
            <>
                {questionCard.map((data: QuestionCardInfo, index ) => {
                    return (
                        <Card title={data.title} className="shadow-md m-2 mb-3" id={ stringify(data.id)} key={index}>
                            <Typography.Paragraph key={data.content}> {data.content} </Typography.Paragraph>
                            {data.tags.map((tag) => {
                                return <Tag  color={tag.color}>{tag.name}</Tag>;
                            })}


                            <Link to={`/viewQuestion/${data.id}`}>
                                <Button type="primary" className="bg-secondary float-right" > View Question </Button>
                            </Link>
                        </Card>
                    )
                })}
            </>
        )
    }



    // function getQuestion(id: number) {
    //     navigate(`/viewQuestion/${id}`)
    // }

    return(
        <>
            <StyledHeader />
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 lg:grid-cols-6 gap-2">
                    <div className="col-span-1 lg:col-span-2">
                        <div className="grid grid-cols-1 gap-2 sticky top-10">
                            <div className="mb-5">
                                <p className="text-4xl font-bold underline underline-offset-8 decoration-secondary">
                                    {" "}
                                    Categories{" "}
                                </p>
                            </div>
                            <div className="pr-10 border-r-4 border-black">
                                <CategoryFilterCards />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1 md:col-span-4 pl-0 lg:pl-5">
                        <QuestionCard />
                    </div>
                </div>
            </div>
        </>
    )
}


export default Category