import React, {useEffect, useState} from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import SideMenu from "../components/AdminSideMenu";
import AdminHeader from "../components/AdminHeader";

const { Header, Content, Footer, Sider } = Layout;

interface AdminLayoutProps {
    children: React.ReactNode,
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {

    const [username, setUsername] = useState<string | undefined>("");
    useEffect(() => {
        const name = localStorage.getItem("name");
        if (name){
            setUsername(name);
        }
    }, [] );

  return (
    <Layout className="h-screen">
        {/*<Header style={{ padding: 0, background: colorBgContainer }} />*/}
        <Sider
            width="250"
            className="h-screen"
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
                console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
        >
            <div className="logo" />
            <SideMenu/>
        </Sider>

        <Layout>
            <AdminHeader userName={username}/>

            <Content style={{ margin: '24px 16px 0' }}>
                {children}
            </Content>
        </Layout>
      {/*<Footer style={{ textAlign: 'center' }}>Askatizah ©2023 </Footer>*/}
    </Layout>
  );
};

export default AdminLayout;