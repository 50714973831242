// import {Link} from "react-router-dom";
// import AskatizahLogo from "../../assets/askatizah_logo.png";
// import {Button} from "antd";
// import { UserOutlined } from '@ant-design/icons';
//
//
// const StyledHeader = () => {
//
//
//     return (
//         <>
//             {/*Specify color */}
//             <nav className="bg-white py-2.5 rounded">
//                 <div className="container flex flex-wrap items-center justify-between mx-auto">
//
//                     <div className="hover:bg-transparent">
//                         <Link to={"/"}>
//                             <img src={AskatizahLogo} className="h-10 sm:ml-5" alt="logo"/>
//                         </Link>
//                     </div>
//
//
//                     {/* TODO: Fix responsive menu data-collapse-toggle */}
//                     <button data-toggle="collapse" data-target="#mobileMenu" type="button" aria-expanded="false" aria-controls="collapseWidthExample"
//                             className="inline-flex items-center p-2 mr-3 rounded-lg md:hidden focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 transition duration-150 ease-in-out">
//                         <svg className="w-6 h-6 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                             <path  data-toggle="collapse" data-target="#mobileMenu" fill-rule="evenodd"
//                                     d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
//                                     clip-rule="evenodd"></path>
//                         </svg>
//                     </button>
//
//
//                     <div className="hidden w-full md:block md:w-auto">
//                         <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
//                             <li>
//                                 <Link to={"/"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
//                                     Home
//                                 </Link>
//                             </li>
//                             <li>
//                                 <Link to={"/category"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
//                                     Questions Bank
//                                 </Link>
//                             </li>
//                             <li>
//                                 <Link to={"/submitQuestion"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
//                                     Submit a Question
//                                 </Link>
//                             </li>
//                             <li>
//                                 <Link to={"/adminlogin"}>
//                                     <Button type={"primary"} className="bg-secondary " size={"large"} icon={<span className="mb-0.5 mr-1.5"><UserOutlined /></span>}>
//                                         Sign in
//                                     </Button>
//                                 </Link>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </nav>
//
//         </>
//     );
// }


// export default StyledHeader


import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AskatizahLogo from '../../assets/askatizah_logo.png';

const StyledHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            {/*Specify color */}
            <nav className="bg-white py-2.5 rounded">
                <div className="container flex flex-wrap items-center justify-between mx-auto">
                    <div className="hover:bg-transparent">
                        <Link to={'/'}>
                            <img src={AskatizahLogo} className="h-10 sm:ml-5" alt="logo" />
                        </Link>
                    </div>

                    <button
                        onClick={toggleMenu}
                        type="button"
                        aria-expanded="false"
                        aria-controls="collapseWidthExample"
                        className="inline-flex items-center p-2 mr-3 rounded-lg md:hidden focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 transition duration-150 ease-in-out"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>

                    <div className={`md:block ${isMenuOpen ? 'block' : 'hidden'} md:w-auto`}>
                        <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                            <li>
                                <Link to={"/"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={"/category"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
                                    Questions Bank
                                </Link>
                            </li>
                            <li>
                                <Link to={"/submitQuestion"} className={"text-primary block py-2 pl-3 pr-4 hover:bg-transparent"}>
                                    Submit a Question
                                </Link>
                            </li>
                            <li>
                                <Link to={"/adminlogin"}>
                                    <Button type={"primary"} className="bg-secondary " size={"large"} icon={<span className="mb-0.5 mr-1.5"><UserOutlined /></span>}>
                                        Sign in
                                    </Button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default StyledHeader;
