import SideMenu from "../../../components/AdminSideMenu";
import 'react-quill/dist/quill.snow.css';
import {Space, Table, Tag, Button, Form, Typography, Select, message} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AdminLayout from "../../../Layout/AdminLayout";
import {
    AnswerResponse, EdittingFormData, EdittingRequest, getAllCategoryWithoutNotSure, getAllTags, getAnswerByQuestionId,
    getQuestionsForAsatizahReview,
    getQuestionsForPublishedQuestions,
    QuestionProcessing, SimpleAnswerData, updateQuestionAnswerEditData
} from "../../../services/api/SnsAPI";
import moment from "moment";
import React, {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import TextEditor from "../../../components/TextEditor";
import {CloseOutlined} from "@ant-design/icons";
import {CategoryResponse} from "../../../../types/api/categoryTypes";


interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

const { Title } = Typography;



const PublishedQuestions = () => {


    const columns: ColumnsType<AnswerResponse> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '2%',
            render: (id) => <p>{id}</p>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (createdAt) => moment(createdAt).format('DD MMM YYYY, hh:mm A')
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            render: (_, record) => (
                <div className="grid grid-cols-3 gap-3">
                    {/*<div>*/}
                    <Button
                        type="primary"
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>


                </div>

            ),
        },
    ];
    const [formDataSelected, setFormDataHere] = useState<AnswerResponse | undefined>(undefined);
    const [data, setData] = useState<AnswerResponse[]>([]);
    const [editting, setEditing] = useState(false);
    const [publishedLoading, setPublishedLoading] = useState(false);
    useEffect(() => {
        getQuestionsForPublishedQuestions()
            .then((res) => {
                // @ts-ignore
                if (res) {
                    console.log("Top level data")
                    console.log(res)
                    setData(res)
                }
                console.log("This is data published")
                console.log(data)
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    const handleEdit = (record: AnswerResponse) => {
        console.log("HandleEdit")
        console.log(record)
        setFormDataHere(record);
        setEditing(true);
    }

    const [answerId, setAnswerId] = useState<number | undefined>(0);

    const onFinishHandler = async (formData: EdittingFormData) => {
        setPublishedLoading(true);
        console.log("Form data");
        console.log(formData);
        console.log("Starting to edit the published question");
        console.log(formDataSelected?.id);


        await getAnswerByQuestionId(formDataSelected?.id)
            .then((res: SimpleAnswerData | null ) => {
                console.log("This is the answer id");

                if (res === null) {
                    message.error("Error with API anwswer endpoint");
                    return;
                }
                console.log("Check here----")
                console.log(res?.id);
                // setAnswerId(res?.id);

                const formDataToBeSent: EdittingRequest = {
                    questionId: formDataSelected?.id,
                    answerId: res?.id,
                    title: formData?.title,
                    categoryId: formData?.category,
                    tags: formData?.tags.map((tagId: number) => {
                        console.log("Here")
                        console.log(formDataSelected)
                        const tag = formDataSelected?.tags.find((t) => t.id === tagId);
                        return {
                            id: tagId,
                            name: tag!.name,
                            color: tag!.color,
                        };
                    }),
                    questionContent: formData?.questionContent,
                    answerContent: formData?.answerContent,
                };

                console.log("Form data TO BE SENT ");
                console.log(formDataToBeSent);

                updateQuestionAnswerEditData(formDataToBeSent)
                    .then((res) => {
                        console.log("Edit the question");
                        console.log(res);
                        message.success("Updated the published question");
                        setEditing(false);
                    })
                    .catch((err: Error) => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        setPublishedLoading(false);
                    });
            })
            .catch((err) => {
                message.error("Error getting answer id");
                setPublishedLoading(false);
            });
    };


    const onChangeTextEditor = (e: any) => {
        console.log(e)
    }

    const EditingComponent = () => {

        interface Category {
            name:string;
            id:number;
        }
        interface Tag {
            name:string;
            id:number;
            color:string;
        }

        const removeIcon = () => <CloseOutlined style={{ color: "black" }} />;
        const [categoryOptions, setCategoryOptions] = useState<Category[] | void>([]);
        const [tagsOptions, setTagsOptions] = useState<Tag[] | void>([]);

        useEffect(() => {
        //      Call Category and tags for all.
            getAllCategoryWithoutNotSure()
                .then((res) => {
                    setCategoryOptions(res)
                })
            getAllTags()
                .then((res) => {
                    setTagsOptions(res)
                })

        },[])

        // useEffect(() => {
        // const categoryOptions = data.map((item) => (
        //     <Select.Option key={item.category.id} value={item.category.id}>
        //         {item.category.name}
        //     </Select.Option>
        // ));

        // Map through tags array and create Select.Option components
        // const tagsOptions = data.map((item) =>
            // item.tags.map((tag) => (
            //     <Select.Option key={tag.id} value={tag.id}>
            //         <Tag color={tag.color}>
            //             {tag.name}
            //         </Tag>
            //     </Select.Option>
            // ))
        // );
            // setCategoryOptions(categoryOptions);
            // setTagsOptions(tagsOptions.flat());
        // }, []);

        // TODO: Stopped here for the editting part, need to add category and tags. Need add dynamic category and tags for QuestionReview as well
        // TODO: Add env variable for Base URL Frontned on the netlify

        return (
            <div>
                <Form initialValues={
                    {
                        title:formDataSelected?.title,
                        category:formDataSelected?.category.id,
                        tags:formDataSelected?.tags.map((tag) => tag.id),
                        questionContent:formDataSelected?.questionContent,
                        answerContent:formDataSelected?.answerContent,
                    }} onFinish={onFinishHandler} size="large">

                    <Title level={3}>Question Title</Title>
                    <Form.Item name="title" rules={[{required: true}]}>
                        <TextArea rows={1}/>
                    </Form.Item>
                    <div className="grid grid-cols-2 gap-8">

                        <div>
                            <Title level={3}>Category</Title>
                            <Form.Item name="category" rules={[{ required: true }]}>
                                <Select>
                                    { categoryOptions?.map( (category) => {
                                        return (
                                            <Select.Option key={category.id} value={category.id}>
                                                {category.name}
                                            </Select.Option>
                                        )
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div>
                            <Title level={3}>Tags</Title>
                            <Form.Item name="tags" rules={[{ required: true }]}>
                                <Select
                                    mode="multiple"
                                    removeIcon={removeIcon}
                                >
                                    { tagsOptions?.map( (tag) => {
                                        return (
                                            <Select.Option key={tag.id} value={tag.id}>
                                                <Tag color={tag.color}>
                                                    {tag.name}
                                                </Tag>
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                                {/*<Input />*/}
                            </Form.Item>
                        </div>

                    </div>
                    <Title level={3}>Question Content</Title>
                    <Form.Item className="bg-white" name="questionContent" rules={[{required: true}]}>
                        <TextArea
                            rows={3}
                            />
                    </Form.Item>
                    <Title level={3}>Answer</Title>
                    <Form.Item className="bg-white" name="answerContent" rules={[{required: true}]}>
                        <TextEditor
                            value={formDataSelected?.answerContent}
                            placeholder=""
                            onChange={onChangeTextEditor}/>
                    </Form.Item>
                    <div className="float-right mt-2">
                        <Button type="primary" htmlType="submit" size="large" loading={publishedLoading}>
                            Edit
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }


    return (
        <AdminLayout>
            <div className="container mx-auto">

                {editting ? (
                    <EditingComponent/>
                ) : (
                    <>
                        <p className="text-4xl font-bold underline underline-offset-8 decoration-secondary"> Published Questions </p>
                        <br/>
                        <br/>

                        <Table
                            key={data.length}
                            columns={columns}
                            dataSource={data}
                            pagination={{pageSize: 8}} // set pageSize to 8 rows per page
                        />
                    </>
                )}
            </div>
        </AdminLayout>
    )
}

export default PublishedQuestions